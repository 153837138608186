/* eslint-disable react/jsx-indent */
import React from 'react';
import { Link, graphql } from 'gatsby';
import SEO from './components/SEO';
import CondensedPanel from './components/CondensedPanel';
import Header from './components/Header';
import BlogExcerpts from './components/BlogExcerpts';
import Footer from './components/Footer';

const HomePage = ({
  data: {
    allWordpressPost: {
      nodes: posts 
    },
    heroImage: {
      childImageSharp: {
        full: {
          src: fullSrc
        },
        blur: {
          src: blurSrc,
        }
      }
    }
  }
}) => {
  return (
        <>
        <SEO title="American expat author living in New Zealand" description="Author of the book Woman Enough and blog The Knackered Truth" />
        <CondensedPanel />
        <div className="header-and-main">
          <Header />
          <main className="home__main main" role="main">
            <div className="hero">
              <div className="hero__section hero__section--1" style={{backgroundImage: `url(${fullSrc}), url(${blurSrc})`}} />
              <div className="hero__section hero__section--2">
                <div className="hero__content">
                  <div className="hero__details">
                    <header className="hero__header">
                      <h2 className="hero__book-title">
                        Woman
                        <span>Enough</span>
                      </h2>
                    </header>
                    <blockquote className="hero__quote">
                      <p>The book's greatest feat, I think, is making the reader confront their own lazy assumptions.</p>
                      <cite className="hero__cite">India Lopez, Stuff, May 2019</cite>
                    </blockquote>
                  </div>
                </div>
                <ul className="hero__actions">
                  <li className="hero__action">
                    <Link to="/order/" className="hero__action__link">Buy the Book</Link>
                  </li>
                  <li className="hero__action">
                    <span className="hero__action__delimiter">or</span>
                  </li>
                  <li className="hero__action">
                    <Link to="/blog/why-i-wrote-woman-enough/" className="hero__action__link">Learn More</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="block block--xl-on-md">
              <div className="wrapper">
                <header className="section-header">
                  <h3 className="section-header__title">
                    From My Blog
                  </h3>
                  <div className="section-header__break" />
                  <Link to="/blog/" className="section-header__btn btn">View All</Link>
                </header>
                <BlogExcerpts posts={posts || []} />
              </div>
            </div>
            <div className="home-about">
              <div className="home-about__content">
                <p className="home-about__text">I'm an American expat author living in New Zealand</p>
                <p className="text-center">
                  <Link to="/about-me/" className="home-about__btn btn btn--primary btn--wide btn--strong">Learn More</Link>
                </p>
              </div>
            </div>
          </main>
        </div>
        <Footer />
        </>
        
    );
};

export default HomePage;

export const pageQuery = graphql`
query HomePagePosts {
  allWordpressPost(sort: { fields: date, order: DESC }, limit: 3) {
    nodes {
      ...PostFields
    }
  }

  heroImage:file(relativePath: {eq: "book-cover-no-text.jpg"}) {
    childImageSharp {
      full:fixed(width: 600, height: 600) {
				src
      }
      blur:fixed(width:300, height:300) {
        src
      }
    }
  }}
`;
